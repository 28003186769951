<template>
  <App
    title="淘客池"
    :border="false"
    left-arrow
    @click-left="onNavBarClickLeft"
    @click-right="onNavBarClickRight"
  >
    <div
      slot="right"
      v-auth="'GuestPool-MassDistribution'"
      style="color:#FB7E01;"
    >
      <a>{{ state === 0 ? '批量分配' : '取消分配' }}</a>
    </div>
    <div slot="extra">
      <Tabs
        v-model="active"
        @click="tab"
      >
        <Tab title="全部"></Tab>
        <Tab title="门店"></Tab>
        <Tab title="城市"></Tab>
      </Tabs>
    </div>
    <List
      ref="list"
      v-model="selectionList"
      :dataList="dataList"
      @listclaim="listclaim"
      @on-load="onLoad"
    />
    <Footer
      slot="footer"
      v-show="state === 1"
      :selection="selectionList"
      :dataList="dataList"
      @on-select="toggleAll(true)"
      @on-cancel="toggleAll(false)"
    />
  </App>
</template>
<script>
import { Tabs, Tab, Toast } from 'vant'
import { taokePoll, areatree, getSource } from '@/api/chargequery'
import List from './components/list'
import Footer from './components/footer'
export default {
  name: 'taoGuestPool',
  components: {
    Tabs,
    Tab,
    List,
    Footer
  },
  provide () {
    return {
      getState: () => this.state
    }
  },
  data () {
    return {
      active: 0, // 0: 全部 1: 门店 2: 城市
      state: 0, // 0: 默认值 1: 批量分配
      isLoading: false,
      transmission: 'all',
      dataList: [],
      selectionList: [],
      cityList: [],
      clueSource: []
    }
  },
  watch: {
    state (value) {
      if (value === 0) {
        this.selectionList = []
      }
    }
  },
  methods: {
    // 认领成功刷新页面
    listclaim () {
      this.getData()
    },
    onRefresh () {
      console.log('1112223233')
    },
    tab () {
      if (this.active === 0) {
        this.transmission = 'all'
      }
      if (this.active === 1) {
        this.transmission = 'store'
      }
      if (this.active === 2) {
        this.transmission = 'area'
      }
      this.getData()
    },
    // 获取处理上牌城市展示
    async areatree () {
      const res = await areatree()
      if (res.code === '000000') {
        this.cityList = res.data
        this.dataList.forEach(item => {
          this.cityList.forEach(el => {
            if (item.intentionCity === el.value) {
              item.intentionCity = el.label
            } else {
              el.children.forEach(elch => {
                if (item.intentionCity === elch.value) {
                  item.intentionCity = elch.label
                }
              })
            }
          })
        })
      } else {
        Toast('上牌城市获取失败')
      }
    },
    // 获取线索来源
    async getSource () {
      Toast.loading({
        message: 'Loading...',
        forbidClick: true,
        loadingType: 'spinner',
        overlay: true
      })
      const res = await getSource()
      if (res.code === '000000') {
        this.clueSource = res.data.map(e => {
          return { 'code': e.threelevelCode, 'text': e.threelevelName }
        })
      } else {
        Toast(res.msg)
      }
    },
    // 获取数据
    async getData () {
      Toast.loading({
        message: 'Loading...',
        forbidClick: true,
        loadingType: 'spinner',
        overlay: true
      })
      const res = await taokePoll(
        {
          page: 1,
          size: 10
        },
        { type: this.transmission }
      )
      Toast.clear()
      if (res.code === "000000") {
        this.dataList = res.data.records
        this.dataList.forEach((item, index) => {
          if (!item.id) item.id = index
          // 根据线索码处理线索来源
          this.clueSource.forEach(score => {
            if (item.threeLevelChannel === score.code) {
              item.threeLevelChannel = score.text
            }
          })
          // 根据城市码处理显示城市
          this.cityList.forEach(el => {
            if (item.intentionCity === el.value) {
              item.intentionCity = el.label
            } else {
              el.children.forEach(elch => {
                if (item.intentionCity === elch.value) {
                  item.intentionCity = elch.label
                }
              })
            }
          })
        })
      } else {
        Toast(res.msg)
      }
    },
    async init () {
      this.selectionList = []
      this.state = 0
      await this.getSource()
      await this.getData()
      await this.areatree()
    },
    onLoad () {
      // todo
      this.isLoading = true
      this.getData().then(() => {
        this.$refs.list.list.loading = true
        this.isLoading = false
      })
    },
    onNavBarClickLeft () {
      this.$router.go(-1)
    },
    onNavBarClickRight () {
      if (this.isLoading) return
      this.state = this.state === 0 ? 1 : 0
    },
    toggleAll (selectAll) {
      this.$refs.list.toggleAll(selectAll)
    }
  },
  mounted () {
    this.init()
  }
}
</script>
<style lang="less" scoped>
@import url('@/style/vant-patch.less');
</style>